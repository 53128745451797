import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { ApolloProvider } from '@apollo/client';
import propTypes from 'prop-types';
import { ChakraProvider } from '@chakra-ui/react';
import { ColorScheme, outdoorly } from '@outdoorly/theme';
import Head from 'next/head';
import { MarkingProvider } from '@markings/react';
import NProgress from 'nprogress';
import { DefaultSeo } from 'next-seo';
import { ErrorBoundary } from '@sentry/react';

import { AuthProvider } from '../providers/auth';
import { useApollo } from '../lib/auth/apollo';
import * as FullStory from '@fullstory/browser';

import 'nprogress/nprogress.css';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import '../components/product/carousel-styles.css';
import 'react-photoswipe/lib/photoswipe.css';
import '../components/landing-page/expert-swiper.css';
import '@outdoorly/photoswipe/src/photoswipe.scss';
import { CheckoutProvider } from '../hooks/use-checkout';
import { QualificationPrompter } from '../providers/qualifications';
import { ErrorView } from '../components/error-view';
import * as ga from '../lib/ga';

const LazyCongratulator = dynamic(() =>
  import('../components/post-sign-in-congratulator')
);

import dynamic from 'next/dynamic';

const isProduction = process.env.NODE_ENV === 'production';

NProgress.configure({ showSpinner: false });

if (typeof window !== 'undefined') {
  FullStory.init({ orgId: 'EAE8Q', devMode: !isProduction });
}
export default function App({ Component, pageProps }) {
  const apolloClient = useApollo(pageProps.initialApolloState);
  const router = useRouter();

  useEffect(() => {
    //we have to import like this until they merge this PR https://github.com/zsajjad/react-facebook-pixel/pull/72
    const ReactPixel = require('react-facebook-pixel');
    ReactPixel?.default?.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID);

    const handleRouteChange = (url) => {
      ga.pageview(url);
      NProgress.done();
      ReactPixel?.default?.pageView();
    };
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange);

    //NProgress loading bar
    router.events.on('routeChangeStart', () => {
      NProgress.start();
    });
    router.events.on('routeChangeError', () => NProgress.done());

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('routeChangeError', () => NProgress.done());
      router.events.off('routeChangeStart', () => {
        NProgress.start();
      });
    };
  }, [router.events]);

  return (
    <ErrorBoundary fallback={ErrorView}>
      <MarkingProvider enabled={false}>
        <ChakraProvider theme={outdoorly}>
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <link
              href="https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,500;1,400;1,500&display=swap"
              rel="stylesheet"
            />
            <link rel="shortcut icon" href="../images/icons/favicon.ico" />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/apple-touch-icon.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/favicon-32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/favicon-16x16.png"
            />
            <link rel="manifest" href="/site.webmanifest" />
            <link
              rel="mask-icon"
              href="/safari-pinned-tab.svg"
              color="#3c3c3c"
            />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ffffff" />
          </Head>
          <DefaultSeo
            title="Outdoorly"
            twitter={{
              cardType: 'summary_large_image',
              site: '@outdoorlygear',
              handle: '@outdoorlygear',
            }}
            openGraph={{
              url: `https://www.outdoorly.com/`,
              type: 'website',
              site_name: 'Outdoorly',
              locale: 'en_US',
              images: [
                {
                  url: 'https://www.outdoorly.com/images/default-outdoorly.png',
                },
              ],
            }}
          />
          <ColorScheme>
            <ApolloProvider client={apolloClient}>
              <AuthProvider>
                <QualificationPrompter>
                  <CheckoutProvider>
                    <Component {...pageProps} />
                    <LazyCongratulator />
                  </CheckoutProvider>
                </QualificationPrompter>
              </AuthProvider>
            </ApolloProvider>
          </ColorScheme>
        </ChakraProvider>
      </MarkingProvider>
    </ErrorBoundary>
  );
}

App.propTypes = {
  Component: propTypes.any.isRequired,
  pageProps: propTypes.object,
  router: propTypes.object,
};
