import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  useDisclosure,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { Alert } from '@outdoorly/alert';
import { Badge } from '@outdoorly/badge';
import { Button, IconButton } from '@outdoorly/button';
import { Card } from '@outdoorly/card';
import { Divider } from '@outdoorly/divider';
import {
  Edit3Icon,
  MoreVerticalIcon,
  RefreshCwIcon,
  Trash2Icon,
} from '@outdoorly/icons';
import { Text } from '@outdoorly/text';
import gql from 'graphql-tag';
import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { DeleteDialog } from '../delete-dialog';
import dynamic from 'next/dynamic';

const DynamicAddQualifications = dynamic(() =>
  import('../qualifications/index')
);

export const QualificationQuery = gql`
  fragment applicationFragment on Application {
    id
    status
    role
    newQualification
    userQualification {
      id
    }
    qualification {
      name
      logoImage {
        url
      }
    }
  }

  query QualificationList {
    me {
      id
      applications(
        first: 50
        filter: {
          status: [
            ADDITIONAL_INFO_REQUIRED
            COMPLETED_BY_USER
            TEMPORARILY_REJECTED
            PERMANENTLY_REJECTED
          ]
        }
      ) {
        edges {
          node {
            ...applicationFragment
          }
        }
      }
      userQualifications(first: 20) {
        edges {
          node {
            id
            qualification {
              name
              logoImage {
                url
              }
            }
            endTime
            role
            revoked
            active
          }
        }
      }
    }
  }
`;

const Application = (application) => {
  const router = useRouter();

  const badgeMapper = {
    ADDITIONAL_INFO_REQUIRED: { color: 'gold', label: 'Pending' },
    COMPLETED_BY_USER: { color: 'gold', label: 'Pending' },
    TEMPORARILY_REJECTED: { color: 'red', label: 'Pending' },
    PERMANENTLY_REJECTED: { color: 'gray', label: 'Rejected' },
  };

  const [applicationDelete, { loading }] = useMutation(
    gql`
      mutation ApplicationDelete($id: ID!) {
        applicationDelete(id: $id) {
          application {
            id
          }
        }
      }
    `
  );

  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box
      borderWidth="1px"
      borderRadius="xl"
      borderColor="scheme.border"
      padding="4"
    >
      <Stack spacing="4">
        <Flex justify="space-between" align="center">
          <Stack align="center" direction="row" spacing="4">
            <Image
              boxSize="12"
              objectFit="cover"
              borderRadius="lg"
              src={application.qualification?.logoImage?.url}
              fallbackSrc={`https://via.placeholder.com/150?text=${(
                application.qualification?.name ?? application.newQualification
              )
                .charAt(0)
                .toUpperCase()}`}
              alt={application.qualification?.name}
            />

            <Stack spacing={2} align="start">
              <Box py={1}>
                <Text fontWeight="500">
                  {application.qualification?.name ??
                    application.newQualification}
                </Text>
              </Box>
              <HStack>
                <Text color="scheme.textSecondary">{application.role}</Text>
                <Badge
                  variant="subtle"
                  colorScheme={badgeMapper[application.status]?.color}
                  label={badgeMapper[application.status]?.label}
                />
              </HStack>
            </Stack>
          </Stack>

          <Box>
            <Menu placement="auto-end" isLazy>
              <MenuButton
                as={IconButton}
                variant="ghost"
                icon={<MoreVerticalIcon height={6} width={6} />}
              />
              <MenuList minWidth={24}>
                {[
                  'COMPLETED_BY_USER',
                  'ADDITIONAL_INFO_REQUIRED',
                  'TEMPORARILY_REJECTED',
                ].includes(application.status) && (
                  <MenuItem
                    icon={<Edit3Icon />}
                    onClick={() => {
                      router.replace(
                        `?applicationId=${encodeURIComponent(application.id)}`
                      );
                    }}
                  >
                    <Text>Add documents</Text>
                  </MenuItem>
                )}
                <MenuItem
                  icon={<Trash2Icon color="scheme.textError" />}
                  onClick={onOpen}
                >
                  <Text color="scheme.textError">Delete</Text>
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Flex>

        {['ADDITIONAL_INFO_REQUIRED', 'TEMPORARILY_REJECTED'].includes(
          application.status
        ) && (
          <Alert
            variant="solid"
            status="warning"
            description="Hey! We can’t approve you until you add a supporting document to your application."
            actions={{
              primary: {
                label: 'Add a supporting document',
                onClick: () => {
                  router.replace(
                    `?applicationId=${encodeURIComponent(application.id)}`
                  );
                },
              },
            }}
          />
        )}
      </Stack>

      <DeleteDialog
        typeName="Application"
        isOpen={isOpen}
        onClose={onClose}
        deleting={loading}
        onDelete={() => {
          applicationDelete({
            variables: { id: application.id },
            update: (cache) => {
              const data = cache.readQuery({ query: QualificationQuery });
              const applications = data.me.applications.edges.filter(
                ({ node: { id: itemId } }) => itemId !== application.id
              );
              cache.writeQuery({
                query: QualificationQuery,
                data: {
                  me: { ...data.me, applications: { edges: applications } },
                },
              });
            },
          }).then(() => onClose());
        }}
      />
    </Box>
  );
};

const Qualification = (userQualification) => {
  const router = useRouter();

  const [userQualificationDelete, { loading }] = useMutation(
    gql`
      mutation UserQualificationDelete($id: ID!) {
        userQualificationDelete(id: $id) {
          userQualification {
            id
          }
        }
      }
    `
  );

  const { isOpen, onClose, onOpen } = useDisclosure();

  const expiresSoon = userQualification.endTime
    ? dayjs(userQualification.endTime).isBefore(dayjs().add(30, 'day'))
    : false;
  const isExpired = userQualification.endTime
    ? dayjs(userQualification.endTime).isBefore(dayjs())
    : false;

  const shouldShowNeverExpires = userQualification.endTime
    ? dayjs(userQualification.endTime).diff(dayjs(), 'year') > 10
    : true;

  return (
    <Box
      borderWidth="1px"
      borderRadius="xl"
      borderColor="scheme.border"
      padding="4"
    >
      <Stack spacing="4">
        <Flex justify="space-between" align="center">
          <Stack align="center" direction="row" spacing="4">
            <Image
              boxSize="12"
              objectFit="cover"
              borderRadius="lg"
              src={userQualification.qualification.logoImage?.url}
              fallbackSrc={`https://via.placeholder.com/150?text=${userQualification.qualification.name}`}
              alt={userQualification.qualification.name}
            />
            <Stack spacing="3">
              <Text fontWeight="500">
                {userQualification.qualification.name}
              </Text>
              <Wrap align="center" spacing="2" wrap="">
                <WrapItem>
                  <Text color="scheme.textSecondary">
                    {userQualification.role}
                    {shouldShowNeverExpires
                      ? ' • Never expires'
                      : ` • Expire${isExpired ? 'd' : 's'} ${dayjs().to(
                          dayjs(userQualification.endTime)
                        )}`}
                  </Text>
                </WrapItem>

                {userQualification.revoked && (
                  <WrapItem>
                    <Badge
                      variant="subtle"
                      colorScheme="danger"
                      label="Revoked"
                    />
                  </WrapItem>
                )}

                {userQualification.expired && (
                  <WrapItem>
                    <Badge
                      variant="subtle"
                      colorScheme="gray"
                      label="Expired"
                    />
                  </WrapItem>
                )}
              </Wrap>
            </Stack>
          </Stack>

          <Box>
            <Menu placement="auto-end">
              <MenuButton
                as={IconButton}
                icon={<MoreVerticalIcon height={6} width={6} />}
                variant="ghost"
              />
              <MenuList minWidth={24}>
                {userQualification.expired && (
                  <MenuItem
                    icon={<RefreshCwIcon />}
                    onClick={() => {
                      router.replace(
                        `?userQualificationId=${encodeURIComponent(
                          userQualification.id
                        )}`
                      );
                    }}
                  >
                    <Text>Renew</Text>
                  </MenuItem>
                )}
                <MenuItem
                  icon={<Trash2Icon color="scheme.textError" />}
                  onClick={onOpen}
                >
                  <Text color="scheme.textError">Delete</Text>
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Flex>

        {expiresSoon && (
          <Alert
            variant="solid"
            status="warning"
            description={
              isExpired
                ? 'Hey! Your qualification has expired. Renew it to continue shopping on Outdoorly.'
                : 'Hey! Your qualification expires soon.'
            }
            actions={{
              primary: {
                label: 'Renew',
                onClick: () => {
                  router.replace(
                    `?userQualificationId=${encodeURIComponent(
                      userQualification.id
                    )}`
                  );
                },
              },
            }}
          />
        )}
      </Stack>

      <DeleteDialog
        typeName="Qualification"
        isOpen={isOpen}
        onClose={onClose}
        deleting={loading}
        onDelete={() => {
          userQualificationDelete({
            variables: { id: userQualification.id },
            update: (cache) => {
              const data = cache.readQuery({ query: QualificationQuery });
              const newQuals = data.me.userQualifications.edges.filter(
                ({ node: { id: itemId } }) => itemId !== userQualification.id
              );
              cache.writeQuery({
                query: QualificationQuery,
                data: {
                  me: { ...data.me, userQualifications: { edges: newQuals } },
                },
              });
            },
          }).then(() => onClose());
        }}
      />
    </Box>
  );
};

export const Qualifications = () => {
  // For the Application Modal
  const router = useRouter();
  const { applicationId, userQualificationId } = router.query;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, loading, refetch } = useQuery(QualificationQuery, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (applicationId || userQualificationId) {
      onOpen();
    }
  }, [applicationId, userQualificationId, onOpen]);

  const applications = data?.me?.applications.edges ?? [];

  const currentApplications = applications.filter(
    ({ node }) => node.status !== 'PERMANENTLY_REJECTED'
  );

  // Filter out qualifications which are being renewed.
  const currentQualifications =
    data?.me?.userQualifications.edges.filter(
      ({ node: qualNode }) =>
        !currentApplications.some(
          ({ node: appNode }) => appNode.userQualification?.id === qualNode.id
        )
    ) ?? [];

  const rejectedApplications = applications.filter(
    ({ node }) => node.status === 'PERMANENTLY_REJECTED'
  );

  const hasNoItems =
    currentApplications.length +
      currentQualifications.length +
      rejectedApplications.length <
      1 && !loading;

  return (
    <Card borderRadius="xl" boxShadow="sm">
      <Stack spacing="lg" marginBottom="lg">
        <Text as="h1" size="lg" fontWeight="medium">
          Qualifications
        </Text>
        <Text color="scheme.textSecondary">
          Adding a qualification opens you up to exclusive deals, access, and
          discounts!
        </Text>
        <Divider />
        {loading && (
          <Flex py={6} justify="center">
            <Spinner />
          </Flex>
        )}
        {currentApplications.map(({ node }) => (
          <Application {...node} key={node.id} />
        ))}
        {currentQualifications.map(({ node }) => (
          <Qualification {...node} key={node.id} />
        ))}
        {rejectedApplications.map(({ node }) => (
          <Application {...node} key={node.id} />
        ))}
        {hasNoItems && (
          <Box py={4}>
            <Text color="scheme.textSecondary" align="center">
              {
                "You don't have any qualifications yet. Add one to access discounts from our brands!"
              }
            </Text>
          </Box>
        )}
        <Button colorScheme="red" variant="solid" onClick={onOpen}>
          Add a qualification
        </Button>
        {isOpen && (
          <DynamicAddQualifications
            isOpen={isOpen}
            onClose={() => {
              router.replace('?');
              refetch();
              onClose();
            }}
            applicationForUpdate={applicationId}
            userQualificationForRenewal={userQualificationId}
          />
        )}
      </Stack>
    </Card>
  );
};
