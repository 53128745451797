import { NetworkStatus } from '@apollo/client/core';

export const makeNetworkStatus = (
  networkStatus: NetworkStatus
): {
  loading: boolean;
  fetchingMore: boolean;
  refetching: boolean;
  error: boolean;
  polling: boolean;
  ready: boolean;
} => {
  return {
    loading:
      networkStatus === NetworkStatus.loading ||
      networkStatus === NetworkStatus.setVariables,
    fetchingMore: networkStatus === NetworkStatus.fetchMore,
    refetching: networkStatus === NetworkStatus.refetch,
    error: networkStatus === NetworkStatus.error,
    polling: networkStatus === NetworkStatus.poll,
    ready: networkStatus === NetworkStatus.ready,
  };
};
