/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  verifyBeforeUpdateEmail as fbVerifyBeforeUpdateEmail,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  verifyPasswordResetCode as fbVerifyPasswordResetCode,
  confirmPasswordReset as fbConfirmPasswordReset,
  checkActionCode as fbCheckActionCode,
  applyActionCode as fbApplyActionCode,
  signInWithCustomToken as fbSignInWithCustomToken,
  updatePassword as fbUpdatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from 'firebase/auth';
import { auth } from '../../lib/auth/firebaseClient';

export const updateEmail = async (
  password: string,
  newEmail: string
): Promise<void> => {
  return auth.currentUser && auth.currentUser.email
    ? reauthenticateWithCredential(
        auth.currentUser,
        EmailAuthProvider.credential(auth.currentUser.email, password)
      )
        .then((cred) =>
          cred.user
            ? fbVerifyBeforeUpdateEmail(cred.user, newEmail)
            : Promise.reject('cannot update email')
        )
        // update the current user's token, now that we have a new email
        .then(() => void auth.currentUser?.getIdToken())
    : Promise.reject(
        new Error('Cannot update email. User is not authenticated.')
      );
};

export const updatePassword = (oldPassword: string, newPassword: string) => {
  return auth.currentUser && auth.currentUser.email
    ? reauthenticateWithCredential(
        auth.currentUser,
        EmailAuthProvider.credential(auth.currentUser.email, oldPassword)
      )
        .then((newCreds) =>
          newCreds.user
            ? fbUpdatePassword(newCreds.user, newPassword)
            : Promise.reject('cannot update password')
        )
        .then(() => auth.currentUser && auth.currentUser.getIdToken())
    : Promise.reject(new Error('no user, cannot update password'));
};

export const sendResetPasswordEmail = async (email?: string): Promise<void> => {
  const userEmail = auth.currentUser?.email;

  if (email !== undefined || userEmail != null)
    await sendPasswordResetEmail(auth, email ?? userEmail!);
};

export const signUpWithEmail = async (email: string, password: string) =>
  createUserWithEmailAndPassword(auth, email, password).catch((error) => {
    // usused! but why?
    // const errorMessage = ERROR_CODE_MESSAGES[error.code as keyof ERROR_CODE_KEY_MAP] ?? error.message;
    if (error.code === 'auth/weak-password') {
      return Promise.reject(
        'Your chosen password is too weak. Add a little spice to it.'
      );
    } else if (error.code === 'auth/invalid-email') {
      return Promise.reject(
        'The provided email address is not valid. Please check it and try again.'
      );
    } else if (error.code === 'auth/email-already-in-use') {
      return Promise.reject(
        'The provided email is already associated with an Outdoorly account. Please log in instead.'
      );
    } else return Promise.reject(error.message);
  });

export const verifyPasswordResetCode = async (code: string) => {
  return fbVerifyPasswordResetCode(auth, code).catch((error) => {
    if (error.code === 'auth/expired-action-code') {
      return Promise.reject(
        'This link has expired. Please try resetting your password again.'
      );
    } else {
      return Promise.reject('This link is not valid.');
    }
  });
};

export const confirmPasswordReset = async (code: string, newPassword: string) =>
  fbConfirmPasswordReset(auth, code, newPassword).catch((error) => {
    if (error.code === 'auth/weak-password') {
      return Promise.reject(
        'Your chosen password is too weak. Add a little spice to it.'
      );
    } else if (error.code === 'auth/expired-action-code') {
      return Promise.reject(
        'This link has expired. Please try resetting your password again.'
      );
    } else {
      return Promise.reject('This link is not valid.');
    }
  });

export const checkActionCode = async (code: string) =>
  fbCheckActionCode(auth, code).catch((error) => {
    if (error.code === 'auth/expired-action-code') {
      return Promise.reject('This link has expired.');
    } else {
      return Promise.reject('This link is not valid.');
    }
  });

export const applyActionCode = async (code: string) =>
  fbApplyActionCode(auth, code).catch((error) => {
    if (error.code === 'auth/expired-action-code') {
      return Promise.reject('This link has expired.');
    } else {
      return Promise.reject('This link is not valid.');
    }
  });

export const signInWithCustomToken = async (token: string) =>
  fbSignInWithCustomToken(auth, token).catch((error) => {
    if (error.code === 'auth/invalid-custom-token') {
      return Promise.reject('This link is not valid.');
    }
    return Promise.reject(error);
  });

/* eslint-enable @typescript-eslint/explicit-module-boundary-types */
