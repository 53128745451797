import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { Button, ButtonProps } from '@outdoorly/button';
import React, { useRef } from 'react';

export type DeleteDialogProps = {
  typeName: string;
  deleting: boolean;
  isOpen: boolean;
  onClose: () => void;
  onDelete: ButtonProps['onClick'];
};

export const DeleteDialog: React.FC<DeleteDialogProps> = ({
  typeName,
  isOpen,
  onClose,
  onDelete,
  deleting,
}) => {
  const cancelRef = useRef();

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent borderRadius="xl">
          <AlertDialogHeader fontWeight="500">
            {`Delete ${typeName}`}
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure? This action cannot be undone.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              variant="subtle"
              ref={cancelRef}
              onClick={onClose}
              isDisabled={deleting}
            >
              Cancel
            </Button>
            <Button
              variant="solid"
              colorScheme="danger"
              onClick={onDelete}
              ml={3}
              isLoading={deleting}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
