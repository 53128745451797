import { setContext } from '@apollo/client/link/context';
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  from,
  NormalizedCacheObject,
  InMemoryCacheConfig,
} from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { getAuthToken, setAuthToken } from './token';
import { getIdToken } from 'firebase/auth';
import { auth } from './firebaseClient';
import { createUploadLink } from 'apollo-upload-client';
const isBrowser = typeof window !== 'undefined';

export const getFirebaseTokenWithFallback = async (): Promise<string> => {
  const backupToken = getAuthToken();
  if (!auth.currentUser) return;
  const token = (await getIdToken(auth.currentUser)) ?? backupToken;
  if (token !== backupToken) {
    setAuthToken({}, token);
  }
  return token;
};

const cacheConfig = (): InMemoryCacheConfig => ({
  typePolicies: {
    CheckoutLine: {
      keyFields: false,
    },
    Shop: {
      fields: {
        navigation: {
          merge(existing, incoming) {
            return { ...existing, ...incoming };
          },
        },
      },
    },
    User: {
      fields: {
        orders: relayStylePagination(({ filter, sortBy }) =>
          JSON.stringify({
            filter: filter ?? null,
            sortBy: sortBy ?? null,
          })
        ),
        referrals: relayStylePagination(),
        reviews: relayStylePagination(),
      },
    },
    Category: {
      fields: {
        products: relayStylePagination(({ filter, sortBy }) =>
          JSON.stringify({
            filter: filter ?? null,
            sortBy: sortBy ?? null,
          })
        ),
      },
    },
    Brand: {
      fields: {
        products: relayStylePagination(
          ({ filter, sortBy, onlyVisibleOnStorefront }) =>
            JSON.stringify({
              filter: filter ?? null,
              sortBy: sortBy ?? null,
              onlyVisibleOnStorefront: onlyVisibleOnStorefront ?? true,
            })
        ),
      },
    },
    CreditWallet: {
      fields: {
        events: relayStylePagination(),
      },
    },
    Product: {
      fields: {
        reviews: relayStylePagination(),
      },
    },
    Query: {
      fields: {
        brands: relayStylePagination(({ filter, sortBy }) =>
          JSON.stringify({
            filter: filter ?? null,
            sortBy: sortBy ?? null,
          })
        ),
        collections: relayStylePagination(({ filter, sortBy }) =>
          JSON.stringify({
            filter: filter ?? null,
            sortBy: sortBy ?? null,
          })
        ),
        products: relayStylePagination(({ filter, sortBy }) =>
          JSON.stringify({
            filter: filter ?? null,
            sortBy: sortBy ?? null,
          })
        ),
        shop: {
          merge(existing, incoming) {
            return { ...existing, ...incoming };
          },
        },
      },
    },
  },
});

export const createApolloClient = ({
  req,
}: { req?: any } = {}): ApolloClient<NormalizedCacheObject> => {
  const authLink: ApolloLink = setContext(async (_, { headers }) => {
    const token = req
      ? getAuthToken({ req })
      : isBrowser
      ? await getFirebaseTokenWithFallback()
      : null;
    return {
      headers: {
        ...headers,
        authorization: token ? `JWT ${token}` : '',
      },
    };
  });
  const httpLink = createUploadLink({
    uri: process.env.NEXT_PUBLIC_API_URL,
    credentials: 'same-origin',
  });

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([authLink, httpLink]),
    cache: new InMemoryCache(cacheConfig()),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
      query: {
        fetchPolicy: 'cache-first',
      },
    },
  });
};

let apolloClient: ReturnType<typeof createApolloClient>;
/**
 * There are three cases where this method is called:
 * 1. SSR with getInitialProps() - In this case we want to pass `req` so we have
 *    access to the user's cookies.
 * 2. SSG with getStaticProps() / no prop getter - In this case we don't pass
 *    req because the request isn't from a user, it's from the SSG service.
 * 3. In the browser - In this case we don't have a req.
 */
export function initializeApollo(
  initialState: NormalizedCacheObject | undefined = undefined,
  { req }: { req?: any } = {}
): ApolloClient<NormalizedCacheObject> {
  const _apolloClient = apolloClient ?? createApolloClient({ req });

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // _apolloClient.cache.restore(initialState);
    const prevState = _apolloClient.extract();

    if (prevState.ROOT_QUERY && initialState.ROOT_QUERY) {
      initialState.ROOT_QUERY = {
        ...initialState.ROOT_QUERY,
        ...prevState.ROOT_QUERY,
      };
    }

    _apolloClient.cache.restore({
      ...prevState,
      ...initialState,
    });
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return _apolloClient;
  }

  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
}

import { useMemo } from 'react';
export function useApollo(
  initialState?: NormalizedCacheObject | undefined
): ApolloClient<NormalizedCacheObject> {
  const store = useMemo(() => {
    return initializeApollo(initialState);
  }, [initialState]);
  return store;
}
