import cookie from 'cookie';
import assert from 'nanoassert';

const isBrowser = typeof window !== 'undefined';

export const parse = (
  { req }: { req?: any } = {},
  options: cookie.CookieParseOptions = {}
): { [key: string]: string } => {
  assert(
    !!req !== isBrowser,
    'Parsing cookies requires a `req` object on the server, but not on the client.'
  );
  const toParse = req && req.headers ? req.headers.cookie : document.cookie;
  return cookie.parse(toParse || '', options);
};

export const set = (
  { res }: { res: any; req?: any },
  name: string,
  value: string,
  options: cookie.CookieSerializeOptions = {}
) => {
  assert(
    !!res !== isBrowser,
    'Setting cookies requires a `res` object on the server, but not on the client.'
  );

  const cookieVal = cookie.serialize(name, value, options);
  if (res) {
    res.__setCookieSent = res.__setCookieSent || {};
    if (res.__setCookieSent[name]) {
      // Spec says last one wins but apparently some proxies / old browsers
      // support is flakey
      console.warn(
        `Attempting to set a cookie (${name}) multiple times. This may not work with some older browsers or proxies`
      );
    } else {
      res.__setCookieSent[name] = true;
    }
    res.setHeader('Set-Cookie', cookieVal);
  } else {
    // NOTE: This does NOT overwrite the cookie value; it appends it (thanks JavaScript).
    document.cookie = cookieVal;
  }
};

export const get = ({ req }: { req: any }, name: string, options: cookie.CookieParseOptions = {}) => {
  assert(
    !!req !== isBrowser,
    'Getting cookies requires a `req` object on the server, but not on the client.'
  );

  return parse({ req }, options)[name];
};

export const expire = ({ req, res }: { req: any; res: any }, name: string) => {
  assert(
    !!(req && res) !== isBrowser,
    'Expiring cookies requires both a `req` & `res` object on the server, but not on the client.'
  );

  const theCookie = get({ req }, name);

  if (typeof theCookie === 'undefined') {
    // Cookie doesn't exist
    return;
  }

  set({ req, res }, name, theCookie, {
    expires: new Date('Thu, 01 Jan 1970 00:00:00 GMT'),
    maxAge: -999999
  });
};
