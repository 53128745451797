export const GTAG_IS_AVAILABLE =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' &&
  typeof window !== 'undefined';

// log the pageview with their URL
export const pageview = (url) => {
  if (GTAG_IS_AVAILABLE)
    window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
      page_path: url,
    });
};

// log specific events happening.
export const event = ({ action, ...rest }) => {
  if (GTAG_IS_AVAILABLE) window.gtag('event', action, rest);
};
