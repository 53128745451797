import React from 'react';
import { VStack, Link, AspectRatio, Tooltip } from '@chakra-ui/react';
import { Text } from '@outdoorly/text';
import { Container } from '@outdoorly/container';
import NextImage from 'next/image';
import whoops from '../public/images/whoops.jpeg';

export const ErrorView = () => {
  return (
    <Container as={VStack} spacing={16} maxW="7xl" pt={16} pb={16}>
      <Text as="h1" size="5xl" fontWeight="bold" align="center" maxW="3xl">
        Whoops!
        <br />
        Something went wrong...
      </Text>
      <Text as="h2" size="2xl" align="center" color="scheme.textSecondary">
        Our tech team has already been notified. Hang tight while we fix it.
      </Text>

      <Tooltip label="Our favourite CEO, eating it.">
        <AspectRatio
          w="90%"
          maxW="850px"
          ratio={16 / 9}
          borderRadius="2xl"
          overflow="hidden"
        >
          <NextImage
            src={whoops}
            placeholder="blur"
            layout="fill"
            objectFit="cover"
          />
        </AspectRatio>
      </Tooltip>

      <Text color="scheme.textSecondary" as="p" size="xl" align="center">
        If you need help with something right away, please email us at{' '}
        <Link href="mailto:help@outdoorly.com">help@outdoorly.com</Link>.
      </Text>
    </Container>
  );
};
