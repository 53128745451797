const isProd =
  !process.env.NEXT_PUBLIC_APP_ENV || !['development', 'test'].includes(process.env.NEXT_PUBLIC_APP_ENV);
// In production, we want to prefix the cookie name so the browser enforces
// security measures.
// See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie#Attributes
export const JWT_COOKIE = `${isProd ? '__Host-' : ''}token`;

// Secure cookies wont work in dev mode because we're not running in a HTTPS
// environment, so we have to disable it.
export const USE_SECURE_COOKIES = isProd;

// From https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
export const EMAIL_REGEX =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
