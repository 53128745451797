import React, { Fragment, useContext, useCallback } from 'react';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { createMachine, assign, StateMachine, send } from 'xstate';
import { useMachine } from '@xstate/react';
import {
  onIdTokenChanged,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  getIdToken,
  AuthErrorCodes,
  User,
  UserCredential,
} from 'firebase/auth';
import * as Sentry from '@sentry/browser';
import * as ga from '../../lib/ga';
import * as FullStory from '@fullstory/browser';
import { GET_USER } from '../queries';

import { setAuthToken, clearAuthToken } from '../../lib/auth/token';
import { auth } from '../../lib/auth/firebaseClient';
import { UPDATE_REGION } from '../../lib/graphql/addresses/mutations';
import axios from 'axios';

export * from './wrappers';

type AuthWindow = Window &
  typeof globalThis & {
    outdoorlyMaybeAuthed: boolean | undefined;
    outdoorlyIsAuthed: boolean | undefined;
  };
declare let window: AuthWindow;

export type ERROR_CODE_KEY_MAP = Pick<
  { [key in typeof AuthErrorCodes[keyof typeof AuthErrorCodes]]: string },
  | 'auth/user-not-found'
  | 'auth/wrong-password'
  | 'auth/invalid-email'
  | 'auth/email-already-in-use'
  | 'auth/weak-password'
  | 'auth/account-exists-with-different-credential'
  | 'auth/cancelled-popup-request'
  | 'auth/popup-blocked'
  | 'auth/popup-closed-by-user'
>;
export const ERROR_CODE_MESSAGES: ERROR_CODE_KEY_MAP = {
  'auth/user-not-found':
    "Oops! We weren't able to sign you in with that email & password.",
  'auth/wrong-password':
    "Oops! We weren't able to sign you in with that email & password.",
  'auth/invalid-email': "Oops! The email you supplied isn't valid.",
  'auth/email-already-in-use':
    "That email is already in use on Outdoorly. If you've forgotten your password, please click 'Log In > Forgot Password?'.",
  'auth/weak-password': 'Your password is a little too weak. Please try again.',
  'auth/account-exists-with-different-credential':
    'Please sign in using your email & password.',
  'auth/cancelled-popup-request': 'Log in cancelled due to closed popup.',
  'auth/popup-blocked': 'Please allow popups for this site.',
  'auth/popup-closed-by-user': 'Log in cancelled due to closed popup.',
};

type AuthContextProps = {
  isInitializing: boolean;
  isLoading: boolean;
  isAuthed: boolean;
  isNotAuthed: boolean;
  login: (creds: { email: string; password: string }) => Promise<void>;
  logout: () => void;
  loginWithGoogle: () => void;
  // loginWithFacebook: () => void;
  error: string | null;
};

const AuthContext = React.createContext<AuthContextProps | undefined>(
  undefined
);

type AuthMachineContext = {
  errorMessage: string | null;
  client: ApolloClient<unknown> | undefined;
};

type AuthMachineStateSchema = {
  /** */
};
type AuthMachineEvent =
  | { type: 'LOGIN' }
  | { type: 'SOFT_LOGOUT' }
  | { type: 'MANUAL_LOGOUT' }
  | { type: 'ERROR'; errorMessage: string }
  | { type: 'AUTHORIZING'; loginPromise: Promise<unknown> };

const authMachine: StateMachine<
  AuthMachineContext,
  AuthMachineStateSchema,
  AuthMachineEvent
> = createMachine<AuthMachineContext, AuthMachineEvent>(
  {
    id: 'auth',
    initial: 'initialLoading',
    context: {
      errorMessage: null,
      client: undefined,
    },
    invoke: [{ src: 'authChanged' }, { src: 'idTokenChanged' }],
    states: {
      initialLoading: {
        invoke: {
          src: 'authInitialized',
          onDone: [
            { target: 'loggedIn', cond: (_, event) => !!event.data },
            { target: 'loggedOut', cond: (_, event) => !event.data },
          ],
          onError: {
            target: 'loggedOut',
            actions: assign({
              errorMessage: (_context, _event) => 'Unable to authenticate',
            }),
          },
        },
      },
      loggedIn: {
        entry: ['refetchObservables', 'setFullStoryBrandPermissions'],
        on: { MANUAL_LOGOUT: 'loggingOut', SOFT_LOGOUT: 'logoutHold' },
      },
      logoutHold: {
        on: { LOGIN: 'loggedIn' },
        // causes a delay on the log in page, so make this small
        // the way to fix this would be to determine if we were
        // truly not logged in during the initialLoading state, but I'm not sure how
        after: { 3000: 'loggingOut' },
      },
      loggingOut: {
        exit: ['clearCache', 'clearToken'],
        invoke: { src: 'logout', onDone: 'loggedOut', onError: 'loggedOut' },
      },
      loggedOut: {
        exit: ['clearError'],
        on: { LOGIN: 'loggedIn', AUTHORIZING: 'authorizing' },
      },
      authorizing: {
        exit: ['setError'],
        invoke: {
          src: (_context, event) =>
            event.type === 'AUTHORIZING'
              ? event.loginPromise
              : Promise.reject({ message: 'No login given' }),
          onDone: '',
          onError: {
            actions: send((_context, event) => {
              const error = event.data;
              const errorMessage =
                ERROR_CODE_MESSAGES[error.code as keyof ERROR_CODE_KEY_MAP] ??
                error.message;
              return { type: 'ERROR', errorMessage };
            }),
          },
        },
        on: { LOGIN: 'loggedIn', ERROR: 'loggedOut' },
      },
    },
  },
  {
    actions: {
      setError: assign({
        errorMessage: (_, event) =>
          event.type === 'ERROR' ? event.errorMessage : null,
      }),
      clearError: assign<AuthMachineContext, AuthMachineEvent>({
        errorMessage: null,
      }),
      clearCache: (context) => {
        context.client?.resetStore();
        Sentry.configureScope((scope) => scope.setUser(null));
      },
      clearToken: () => {
        clearAuthToken();
      },
      refetchObservables: (context) => {
        window.outdoorlyMaybeAuthed = true;
        // There is a chance that we may have fired
        // requests before Firebase auth has initialized.
        // So we re-run those queries with authorization.
        context.client?.reFetchObservableQueries();
      },
      setFullStoryBrandPermissions: (context) => {
        context.client.query({ query: GET_USER }).then(
          ({ data: userData }) =>
            userData &&
            FullStory.setUserVars({
              email: userData.email,
              id: userData.id,
              firstName_str: userData?.firstName || '',
              lastName_str: userData?.lastName || '',
              isStaff_bool: userData?.isStaff || false,
              brandPermissions_strs: getBrandPermissions(userData),
              dateJoined_str: userData?.dateJoined || '',
            })
        );
      },
    },
    services: {
      authInitialized: () =>
        new Promise<User | null>((res) =>
          onAuthStateChanged(
            auth,
            (user) => (res(user), user && identify(user))
          )
        ),
      logout: async (context) => {
        // Used for the fast redirect scripts injected into `<head>`
        delete window.outdoorlyMaybeAuthed;
        delete window.outdoorlyIsAuthed;
        await signOut(auth);
        context.client?.stop();
        await context.client?.clearStore();
        clearAuthToken();
      },
      authChanged: () => (callback) => {
        return onAuthStateChanged(auth, async function (user) {
          if (user) {
            if (!user.email) {
              callback({
                type: 'ERROR',
                errorMessage:
                  'Remark Admin needs access to your email address to log in. Please try logging in again and grant access to your email address.',
              });
              callback('SOFT_LOGOUT');
              return;
            }
            callback('LOGIN');
          } else {
            // since this event only lives on the loggedIn state, the previous value was loggedIn
            callback('SOFT_LOGOUT');
          }
        });
      },
      idTokenChanged: () => () => {
        return onIdTokenChanged(auth, async (user) => {
          // if (!user) {
          //   // console.log('clearing id token for null user', user, 'current auth user', auth.currentUser);
          //   // removing this line eliminates the logging out issue
          //   // the page still might reload, but you won't be logged out when it does
          //   clearAuthToken();
          //   return;
          // }
          if (user) {
            const token = await getIdToken(user);
            setAuthToken({}, token);
          }
        });
      },
    },
  }
);

const identify = (user: User) => {
  // Identify the sentry user
  Sentry.setUser({
    email: user.email,
  });

  FullStory.identify(user.uid, {
    email: user.email,
  });
  try {
    Sentry.setContext('full_story', {
      FS_session_url: FullStory.getCurrentSessionURL(),
    });
  } catch {
    if (process.env.NODE_ENV === 'development') {
      console.error('Sentry session url failed to generate');
    }
  }
};
const getBrandPermissions = (userData) => {
  if (userData.brandPermissions && userData.brandPermissions.length > 0) {
    return userData.brandPermissions.map(
      (permission) =>
        `${permission?.brand?.name}: ${permission?.permissionType}`
    );
  }

  return [];
};

/** I don't think either of these properties are used, but we'll keep them in the interface for now */
type AuthProviderProps = {
  /**
   * Set to `null` to indicate "Not logged in", or a string representing the
   * logged in user's token.
   */
  authToken?: string;
  /**
   * When rendering server-side, the auth check can be run serially before
   * useAuth(), and so this value can be forced. Note that it's only really
   * useful in either `getServerSideProps` or `getInitialProps`.
   */
  isAuthed?: boolean;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const apolloClient = useApolloClient();

  const [authState, send] = useMachine(authMachine, {
    context: { client: apolloClient },
  });

  const logout = useCallback(async () => {
    send('MANUAL_LOGOUT');
  }, [send]);

  const login = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      const loginPromise = signInWithEmailAndPassword(auth, email, password);
      send({ type: 'AUTHORIZING', loginPromise });
      await loginPromise
        .then(() =>
          ga.event({ action: 'login', method: 'email', error: false })
        )
        .catch((errorMessage) =>
          ga.event({
            action: 'login',
            method: 'email',
            error: true,
            errorMessage,
          })
        );
    },
    [send]
  );

  const loginWithGoogle = useCallback(async () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    provider.addScope('openid');
    const userPromise = signInWithPopup(auth, provider);
    send({ type: 'AUTHORIZING', loginPromise: userPromise });
    await userPromise
      .then(async (user: UserCredential) => {
        ga.event({
          action: user.operationType === 'link' ? 'sign_up' : 'login',
          method: 'Google',
          error: false,
        });
        if (user.operationType === 'link') {
          const countryCode = await axios
            .get('/api/countryCode')
            .then((resp) => resp.data)
            .catch(() => {
              console.log('error getting country code');
            });

          if (countryCode === 'CA') {
            apolloClient.mutate({
              mutation: UPDATE_REGION,
              variables: {
                newRegion: 'CA',
              },
            });
          }
        }
      })
      .catch((errorMessage) =>
        ga.event({
          action: 'login',
          method: 'Google',
          error: true,
          errorMessage,
        })
      );
  }, [send]);

  // const loginWithFacebook = useCallback(async () => {
  //   const provider = new FacebookAuthProvider();
  //   provider.addScope('email');
  //   provider.setCustomParameters({ auth_type: 'rerequest' });
  //   const loginPromise = signInWithPopup(auth, provider);
  //   send({ type: 'AUTHORIZING', loginPromise });
  //   await loginPromise;
  // }, [send]);

  // TODO, from here on down we don't rely on any thing in these components, can we export them from the module
  // instead of through the provider?
  return (
    <Fragment>
      {/* <FastAuthCheck /> */}
      <AuthContext.Provider
        value={{
          isInitializing: authState.matches('initialLoading'),
          isLoading:
            authState.matches('initialLoading') ||
            authState.matches('authorizing'),
          isAuthed:
            authState.matches('loggedIn') || authState.matches('logoutHold'),
          isNotAuthed:
            authState.matches('loggedOut') || authState.matches('loggingOut'),
          login,
          logout,
          loginWithGoogle,
          // loginWithFacebook,
          error: authState.context.errorMessage,
        }}
      >
        {children}
      </AuthContext.Provider>
    </Fragment>
  );
};

/**
 * - isAuthed: A "narrow phase" check for authentication. If false, we know the
 *   user is definitely not authenticated. If true, we know the user is
 *   definitely authenticated.
 *   isLoading: Indicates if the value of `isAuthed` is being checked.
 */
export const useAuth = () => {
  // Why the destructure/restructure? To avoid the client from accidentally
  // mutating the context value

  const {
    isAuthed,
    isLoading,
    isNotAuthed,
    isInitializing,
    login,
    logout,
    loginWithGoogle,
    error,
  } = useContext(AuthContext)!;
  return {
    isAuthed,
    isLoading,
    isNotAuthed,
    isInitializing,
    login,
    logout,
    loginWithGoogle,
    error,
  };
};
