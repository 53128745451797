import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Fade, useDisclosure, useToast, Stack } from '@chakra-ui/react';
import { ErrorBoundary } from '@sentry/react';
import { Button } from '@outdoorly/button';
import { BadgeCheckIcon } from '@outdoorly/icons';
import { Text } from '@outdoorly/text';
import React, { useCallback, useContext, useEffect } from 'react';
import { useAuth } from './auth';
import { QualificationQuery } from '../components/profile/qualifications';
import { Card } from '@outdoorly/card';
import { ODCloseButton } from '@outdoorly/button';
import dynamic from 'next/dynamic';

const DynamicQualificationsModal = dynamic(() =>
  import('../components/qualifications/index')
);
const QualificationContext = React.createContext();

export const QualificationPrompter = ({ children }) => {
  const { isAuthed } = useAuth();
  const { data, loading, refetch } = useQuery(QualificationQuery, {
    skip: !isAuthed,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [shown, setShown] = React.useState(false);
  const [isHidden, setIsHidden] = React.useState(false);

  const isQualified = data?.me?.userQualifications.edges.find(
    (edge) => edge.node.active === true
  );

  const hasOpenApplication = data?.me?.applications?.edges.find((edge) =>
    [
      'ADDITIONAL_INFO_REQUIRED',
      'COMPLETED_BY_USER',
      'TEMPORARILY_REJECTED',
    ].includes(edge.node.status)
  );

  const hasNoQualificationsOrOpenApplications =
    !loading && data && !isQualified && !hasOpenApplication;

  useEffect(() => {
    if (hasNoQualificationsOrOpenApplications) {
      setShown(true);
    } else {
      setShown(false);
    }
  }, [hasNoQualificationsOrOpenApplications]);

  const toast = useToast();

  const doIfQualified = useCallback(
    (callable) => {
      if (isQualified) {
        return callable();
      }
      if (hasOpenApplication) {
        const id = 'application-in-review';
        // Don't show the toast if it's already been shown
        if (toast.isActive(id)) return;

        toast({
          id,
          title: 'Hang tight!',
          description:
            "We're reviewing your application. Once you're approved, you can shop away!",
          status: 'info',
          duration: 5000,
          isClosable: true,
        });
      } else onOpen();
    },
    [onOpen, isQualified, toast, hasOpenApplication]
  );

  return (
    <QualificationContext.Provider
      value={{
        isQualified,
        hasNoQualificationsOrOpenApplications,
        doIfQualified,
        hasOpenApplication,
      }}
    >
      {children}
      <ErrorBoundary>
        <Fade
          direction="right"
          reverse
          key="message-card"
          in={shown && !isOpen && !isHidden}
          unmountOnExit
        >
          <Card
            zIndex="docked"
            as={Stack}
            borderWidth={1}
            borderColor="scheme.border"
            bottom="5vh"
            right="5vh"
            ml="5vh"
            position="fixed"
            backgroundColor="white"
            boxShadow="xl"
            spacing="xl"
          >
            <Text size="lg" textAlign="center">
              <strong>Hi there!</strong> 👋
              <br /> Before you can shop, you&apos;ll need to{' '}
              <strong>add a qualification</strong>.
            </Text>
            <Button colorScheme="red" variant="solid" onClick={onOpen}>
              Add a qualification
            </Button>
            <ODCloseButton
              position="absolute"
              top={0}
              right={[6, 8]}
              size="sm"
              onClick={() => setIsHidden(true)}
            />
          </Card>
        </Fade>
        <Fade
          id="hidden-button"
          in={shown && !isOpen && isHidden}
          unmountOnExit
        >
          <Button
            zIndex="docked"
            bottom={0}
            right="5vh"
            position="fixed"
            boxShadow="xl"
            borderBottomRadius={0}
            size="xl"
            leftIcon={<BadgeCheckIcon color="white" height={8} width={8} />}
            onClick={() => setIsHidden(false)}
            colorScheme="red"
            variant="solid"
          >
            Add your qualification
          </Button>
        </Fade>
        {isOpen && (
          <DynamicQualificationsModal
            isOpen={isOpen}
            onClose={() => {
              onClose();
              refetch();
            }}
          />
        )}
      </ErrorBoundary>
    </QualificationContext.Provider>
  );
};

QualificationPrompter.propTypes = {
  children: PropTypes.node,
};

export const useQualification = () => {
  // Why the destructure/restructure? To avoid the client from accidentally
  // mutating the context value
  const {
    isQualified,
    hasNoQualificationsOrOpenApplications,
    doIfQualified,
    hasOpenApplication,
  } = useContext(QualificationContext);

  return {
    isQualified,
    hasNoQualificationsOrOpenApplications,
    doIfQualified,
    hasOpenApplication,
  };
};
