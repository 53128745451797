import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo } from 'react';
import { useAuth } from '../providers/auth';
import {
  ApolloError,
  ApolloQueryResult,
  gql,
  useApolloClient,
  useQuery,
} from '@apollo/client';
import * as ga from '../lib/ga';
import {
  getAnalyticsFromCheckoutLine,
  getAnalyticsFromCheckoutLineUpdate,
} from '../lib/ga/utils';
import { makeNetworkStatus } from './make-network-status';
import { NetworkStatus } from '@apollo/client/core';

const ADDRESS_FRAGMENT = gql`
  fragment addressFragment on Address {
    id
    firstName
    lastName
    streetAddress1
    streetAddress2
    city
    cityArea
    postalCode
    country {
      country
      code
    }
    countryArea
  }
`;

const LITE_CHECKOUT_FRAGMENT = gql`
  fragment liteCheckoutFragment on Checkout {
    id
    isShippingRequired
    readyToComplete
    quantity
    brandCheckouts {
      id
      quantity
      isShippingRequired
      lines {
        id
      }
    }
    pricing {
      subtotalPrice {
        currency
        net {
          amount
        }
      }
      totalMinusStoreCredit {
        gross {
          amount
        }
      }
      savings {
        currency
        amount
      }
      totalPrice {
        currency
        tax {
          amount
        }

        gross {
          amount
        }
      }
    }
  }
`;

const CHECKOUT_SHIPPING_FRAGMENT = gql`
  ${ADDRESS_FRAGMENT}
  fragment checkoutShippingFragment on Checkout {
    id
    isShippingRequired
    shippingAddress {
      ...addressFragment
    }
    readyToComplete
    brandCheckouts {
      id
      isShippingRequired
      shippingMethod {
        id
        name
        price {
          currency
          amount
        }
      }
      availableShippingMethods {
        id
        name
        price {
          currency
          amount
        }
      }
    }
    pricing {
      shippingPrice {
        currency
        net {
          amount
        }
      }
      totalMinusStoreCredit {
        gross {
          amount
        }
      }
      totalPrice {
        currency
        tax {
          amount
        }

        gross {
          amount
        }
      }
    }
  }
`;

export const CHECKOUT_FRAGMENT = gql`
  ${ADDRESS_FRAGMENT}
  fragment checkoutFragment on Checkout {
    id
    isShippingRequired
    useStoreCredit
    shippingAddress {
      ...addressFragment
    }
    readyToComplete
    quantity
    brandCheckouts {
      id
      quantity
      isShippingRequired
      brand {
        id
        name
      }
      shippingMethod {
        id
        name
        price {
          currency
          amount
        }
      }
      availableShippingMethods {
        id
        name
        price {
          currency
          amount
        }
      }
      lines {
        id
        quantity
        humanFriendlyVariantName
        variant {
          id
          quantity
          stockQuantity
          product {
            slug
            id
            name
            thumbnail {
              url
            }
          }
          thumbnail {
            url
          }
          pricing {
            retailPrice {
              currency
              net {
                amount
              }
            }
            userDiscountedPrice {
              currency
              net {
                amount
              }
            }
            discount
          }
        }
        totalPrice {
          currency
          net {
            amount
          }
        }
      }
    }
    pricing {
      subtotalPrice {
        currency
        net {
          amount
        }
      }
      shippingPrice {
        currency
        net {
          amount
        }
      }
      savings {
        currency
        amount
      }
      totalMinusStoreCredit {
        gross {
          amount
        }
      }
      totalPrice {
        currency
        tax {
          amount
        }

        gross {
          amount
        }
      }
    }
  }
`;

export const GET_CHECKOUT = gql`
  query GetCheckout {
    me {
      id
      checkout {
        ...checkoutFragment
      }
      region {
        country
        code
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`;

export const GET_IS_READY = gql`
  query GetIsReady {
    me {
      id
      checkout {
        id
        readyToComplete
      }
    }
  }
`;

const CREATE_CHECKOUT = gql`
  mutation CreateCheckout {
    checkoutCreate(input: { lines: [] }) {
      created
      errors {
        field
        message
      }
      checkoutErrors {
        field
        message
      }
      checkout {
        ...checkoutFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`;

const ADD_LINE_ITEMS_MUTATION = gql`
  mutation addLineItems($checkoutId: ID!, $lines: [CheckoutLineInput]!) {
    checkoutLinesAdd(checkoutId: $checkoutId, lines: $lines) {
      errors {
        field
        message
      }
      checkoutErrors {
        field
        message
      }
      checkout {
        ...liteCheckoutFragment
      }
      lines {
        id
        quantity
        humanFriendlyVariantName
        variant {
          id
          quantity
          stockQuantity
          product {
            slug
            id
            name
            thumbnail {
              url
            }
            brand {
              name
              id
            }
          }
          thumbnail {
            url
          }
          pricing {
            retailPrice {
              currency
              net {
                amount
              }
            }
            userDiscountedPrice {
              currency
              net {
                amount
              }
            }
            discount
          }
          attributes {
            attribute {
              id
              name
            }
            values {
              id
              name
            }
          }
        }
        totalPrice {
          currency
          net {
            amount
          }
        }
      }
    }
  }
  ${LITE_CHECKOUT_FRAGMENT}
`;

const UPDATE_SHIPPING_ADDRESS_MUTATION = gql`
  mutation updateShippingAddress($checkoutId: ID!, $addressId: ID!) {
    checkoutShippingAddressUpdate(
      checkoutId: $checkoutId
      shippingAddressId: $addressId
    ) {
      errors {
        field
        message
      }
      checkoutErrors {
        field
        message
        code
      }
      checkout {
        ...checkoutShippingFragment
      }
    }
  }
  ${CHECKOUT_SHIPPING_FRAGMENT}
`;

const UPDATE_SHIPPING_METHOD = gql`
  mutation updateShippingMethod($brandCheckoutId: ID!, $shippingMethodId: ID!) {
    brandCheckoutShippingMethodUpdate(
      brandCheckoutId: $brandCheckoutId
      shippingMethodId: $shippingMethodId
    ) {
      errors {
        field
        message
      }
      checkoutErrors {
        field
        message
      }
      brandCheckout {
        id
        checkout {
          id
          readyToComplete
          pricing {
            shippingPrice {
              currency
              net {
                amount
              }
            }
            totalMinusStoreCredit {
              gross {
                amount
              }
            }
            totalPrice {
              currency
              tax {
                amount
              }

              gross {
                amount
              }
            }
          }
        }
        shippingMethod {
          id
          name
          price {
            currency
            amount
          }
        }
      }
    }
  }
`;

const UPDATE_CHECKOUT_LINES = gql`
  mutation updateCheckoutLine($checkoutId: ID!, $lines: [CheckoutLineInput]!) {
    checkoutLinesUpdate(checkoutId: $checkoutId, lines: $lines) {
      errors {
        field
        message
      }
      checkoutErrors {
        field
        message
      }
      checkout {
        ...liteCheckoutFragment
      }
      lines {
        id
        quantity
        totalPrice {
          currency
          net {
            amount
          }
        }
      }
    }
  }
  ${LITE_CHECKOUT_FRAGMENT}
`;

class CheckoutError extends Error {
  constructor(message?: string) {
    super(message);
  }
  public errors;
}

type CheckoutContext = {
  checkout: any;
  loading: boolean;
  initialLoad: boolean;
  refetching: boolean;
  error: ApolloError;
  addLineItems: (lines: any) => Promise<void>;
  updateShippingAddress: (addressId: string) => Promise<void>;
  updateBrandCheckoutShippingMethod: (
    brandCheckoutId: string,
    shippingMethodId: string
  ) => Promise<void>;
  refetchIsReadyToComplete: () => Promise<ApolloQueryResult<any>>;
  updateCheckoutLine: (variantId: any, quantity: any) => Promise<void>;
};
// eslint-disable-next-line @typescript-eslint/ban-types
const CheckoutContext = React.createContext<{} | CheckoutContext>({});

// Track the operation so we only ever do it once no matter how many times this
// hook is used.
// This is important as the mutation is _not_ idempotent
let createPromise;

/**
 *
 * @param {object} param0 an object with the errors stored as arrays in the `errors` or `checkourErrors` fields or both
 * @param {string} message the message to put in the error, if any errors are found
 * @returns {void} returns nothing if no errors, throws an error if it finds any
 */
const assertNoCartErrors = (
  { errors, checkoutErrors }: { errors?: any; checkoutErrors?: any } = {},
  message: string
): void => {
  const createErrors = (errors ?? []).concat(checkoutErrors ?? []);

  if (createErrors.length) {
    const error = new CheckoutError(message);
    error.errors = createErrors;
    throw error;
  }
};

const createCheckout = (apolloClient) => {
  createPromise =
    createPromise ??
    apolloClient
      .mutate({ mutation: CREATE_CHECKOUT })
      .then(({ data: { checkoutCreate } }) => {
        assertNoCartErrors(checkoutCreate, 'Unable to create Checkout');

        if (!checkoutCreate?.created && !checkoutCreate?.checkout) {
          throw new Error('Checkout not created');
        }

        apolloClient.query({
          query: GET_CHECKOUT,
          fetchPolicy: 'network-only',
        });

        return checkoutCreate.checkout;
      });
  return createPromise;
};

export const CheckoutProvider: React.FC<unknown> = ({ children }) => {
  const auth = useAuth();
  const apolloClient = useApolloClient();

  const {
    loading,
    data: meData,
    networkStatus,
    error: meError,
  } = useQuery(GET_CHECKOUT, {
    skip: !auth.isAuthed || auth.isLoading,
  });
  // https://www.apollographql.com/docs/react/api/react/hoc/#datanetworkstatus
  const { refetching } = makeNetworkStatus(networkStatus);
  const initialLoad = networkStatus === NetworkStatus.loading;
  const checkout = meData?.me?.checkout;

  const addLineItems = useCallback(
    async (lines) => {
      if (loading || auth.isLoading) {
        throw new Error('Unable to add line item while loading checkout');
      }

      if (!auth.isAuthed) {
        throw new Error('Unable to add line item while not logged in');
      }

      const currentCheckout = checkout ?? (await createCheckout(apolloClient));

      const {
        data: { checkoutLinesAdd },
        errors,
      } = await apolloClient.mutate({
        mutation: ADD_LINE_ITEMS_MUTATION,
        variables: { lines, checkoutId: currentCheckout.id },
      });
      if (errors) {
        const error = new CheckoutError('Unable to add lines to checkout');
        error.errors = errors;
        throw error;
      }
      assertNoCartErrors(checkoutLinesAdd, 'Unable to add lines to checkout');
      ga.event({
        action: 'add_to_cart',
        ...getAnalyticsFromCheckoutLine(checkoutLinesAdd),
      });
    },
    [auth.isLoading, auth.isAuthed, apolloClient, checkout, loading]
  );

  const regionCode = meData?.me?.region?.code;
  const updateShippingAddress = useCallback(
    async (addressId: string) => {
      if (loading || auth.isLoading) {
        throw new Error(
          'Unable to change shipping address while loading checkout'
        );
      }

      if (!auth.isAuthed) {
        throw new Error(
          'Unable to change shipping address while not logged in'
        );
      }

      try {
        const currentCheckout =
          checkout ?? (await createCheckout(apolloClient));

        const {
          data: { checkoutShippingAddressUpdate },
          errors,
        } = await apolloClient.mutate({
          mutation: UPDATE_SHIPPING_ADDRESS_MUTATION,
          variables: {
            addressId: addressId,
            checkoutId: currentCheckout.id,
          },
        });

        const { checkoutErrors } = checkoutShippingAddressUpdate;
        if (checkoutErrors) {
          const regionError = checkoutErrors.find(
            (err) => err.code === 'SHIPPING_ADDRESS_REGION'
          );
          if (regionError) {
            const error = new CheckoutError(
              `You may only ship to addresses in your region, which is currently ${
                regionCode.includes('US')
                  ? 'United States'
                  : regionCode.includes('CA')
                  ? 'Canada'
                  : regionCode
              }. If you're having any issues, please email us at help@outdoorly.com`
            );
            error.errors = errors;
            throw error;
          }
        }
        if (errors) {
          const error = new CheckoutError(
            'Unable to update shipping address on checkout'
          );
          error.errors = errors;
          throw error;
        }

        assertNoCartErrors(
          checkoutShippingAddressUpdate,
          'Unable to update shipping address on checkout'
        );
      } catch (error) {
        if (!error.handled) {
          error.handled = true;
        }
        throw error;
      }
    },
    [auth.isLoading, auth.isAuthed, apolloClient, checkout, loading, regionCode]
  );

  const updateCheckoutLine = useCallback(
    async (variantId, quantity) => {
      if (loading || auth.isLoading) {
        throw new Error('Unable to update cart while loading checkout');
      }

      if (!auth.isAuthed) {
        throw new Error('Unable to update cart while not logged in');
      }

      if (!checkout) {
        throw new Error('Cannot update cart if user has no checkout');
      }

      try {
        const {
          data: { checkoutLinesUpdate },
          errors,
        } = await apolloClient.mutate({
          mutation: UPDATE_CHECKOUT_LINES,
          variables: {
            checkoutId: checkout.id,
            lines: [{ variantId, quantity }],
          },
        });

        if (errors) {
          const error = new CheckoutError('Unable to update cart line');
          error.errors = errors;
          throw error;
        }

        assertNoCartErrors(checkoutLinesUpdate, 'Unable to update cart line');

        if (checkoutLinesUpdate.checkout.quantity > checkout.quantity) {
          ga.event({
            action: 'add_to_cart',
            ...getAnalyticsFromCheckoutLineUpdate({
              checkout,
              checkoutLinesUpdate,
            }),
          });
        } else {
          ga.event({
            action: 'remove_from_cart',
            ...getAnalyticsFromCheckoutLineUpdate({
              checkout,
              checkoutLinesUpdate,
            }),
          });
        }
      } catch (error) {
        if (!error.handled) {
          error.handled = true;
        }
        throw error;
      }
    },
    [auth.isLoading, auth.isAuthed, apolloClient, checkout, loading]
  );

  const updateBrandCheckoutShippingMethod = useCallback(
    async (brandCheckoutId: string, shippingMethodId: string) => {
      if (!auth.isAuthed) {
        throw new Error('Unable to change shipping method while not logged in');
      }

      if (!checkout) {
        throw new Error(
          'Cannot update shipping method if user has no checkout'
        );
      }

      try {
        const {
          data: { brandCheckoutShippingMethodUpdate },
          errors,
        } = await apolloClient.mutate({
          mutation: UPDATE_SHIPPING_METHOD,
          variables: {
            brandCheckoutId,
            shippingMethodId,
          },
        });

        if (errors) {
          const error = new CheckoutError(
            'Unable to update shipping method on checkout'
          );
          error.errors = errors;
          throw error;
        }

        assertNoCartErrors(
          brandCheckoutShippingMethodUpdate,
          'Unable to update shipping method on checkout'
        );
      } catch (error) {
        if (!error.handled) {
          error.handled = true;
        }
        throw error;
      }
    },
    [auth.isLoading, auth.isAuthed, apolloClient, checkout, loading]
  );

  const refetchIsReadyToComplete = useCallback(
    async () =>
      apolloClient.query({ query: GET_IS_READY, fetchPolicy: 'network-only' }),
    [apolloClient]
  );

  return (
    <CheckoutContext.Provider
      value={{
        checkout,
        loading,
        initialLoad,
        refetching,
        error: meError,
        addLineItems,
        updateShippingAddress,
        updateBrandCheckoutShippingMethod,
        refetchIsReadyToComplete,
        updateCheckoutLine,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

CheckoutProvider.propTypes = {
  children: PropTypes.oneOf([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

export const useCheckout = (): CheckoutContext => {
  const {
    checkout,
    loading,
    initialLoad,
    refetching,
    error,
    addLineItems,
    updateShippingAddress,
    updateBrandCheckoutShippingMethod,
    refetchIsReadyToComplete,
    updateCheckoutLine,
  } = useContext(CheckoutContext) as CheckoutContext;

  return {
    checkout,
    loading,
    initialLoad,
    refetching,
    error,
    addLineItems,
    updateShippingAddress,
    updateBrandCheckoutShippingMethod,
    refetchIsReadyToComplete,
    updateCheckoutLine,
  };
};
