import { JWT_COOKIE, USE_SECURE_COOKIES } from './globals';
import * as cookies from './cookies';

/**
 * @param {object} context The request context object
 * @param {object} [context.res] HTTP Response object used to set cookies
 * server side. If not set, will set cookies to `document`.
 */
export const setAuthToken = ({ res }: { res?: any }, token: string): void => {
  // NOTE: We purposely DO NOT set `httpOnly` as we use this same cookie in
  // both client-side and server-side auth situations. This is vulnerable to
  // XSS, but so is the alternative (localStorage). CSRF is mitigated with the
  // `sameSite: 'strict'` cookie policy.

  cookies.set({ res }, JWT_COOKIE, token, {
    // Domain-wide
    path: '/',
    // Must be sent over HTTPS or not at all
    secure: USE_SECURE_COOKIES,
    // Only send it when the request is initiated from the same origin
    sameSite: 'strict'
  });
};

/**
 * @param {object} [context={}] The request context object
 * @param {object} [context.req] Incoming request object used to extract cookies
 * server side. If not set, will extract cookies from `document`.
 */
export const getAuthToken = ({ req }: { req?: any } = {}) => cookies.get({ req }, JWT_COOKIE);

/**
 * @param {object} context The request context object
 * @param {object} [context.req] Incoming request object used to extract cookies
 * server side. If not set, will extract cookies from `document`.
 * @param {object} [context.res] HTTP Response object used to set cookies
 * server side. If not set, will set cookies to `document`.
 */
export const clearAuthToken = ({ req, res }: { req?: any; res?: any } = {}) =>
  cookies.expire({ req, res }, JWT_COOKIE);
