import gql from 'graphql-tag';
export const ADDRESS_FRAGMENT = gql`
  fragment addressFragment on Address {
    id
    firstName
    lastName
    streetAddress1
    streetAddress2
    city
    cityArea
    postalCode
    country {
      code
      country
    }
    countryArea
    phone
    isDefaultShippingAddress
  }
`;
