import { ADDRESS_FRAGMENT } from './fragments';
import gql from 'graphql-tag';

export const CREATE_SHIPPING_ADDRESS = gql`
  mutation CreateShippingAddress($input: AddressInput!) {
    accountAddressCreate(input: $input, type: SHIPPING) {
      errors {
        field
        message
      }
      address {
        ...addressFragment
      }
    }
  }
  ${ADDRESS_FRAGMENT}
`;

export const UPDATE_SHIPPING_ADDRESS = gql`
  mutation UpdateShippingAddress($id: ID!, $input: AddressInput!) {
    accountAddressUpdate(id: $id, input: $input) {
      errors {
        field
        message
      }
      address {
        ...addressFragment
      }
    }
  }
  ${ADDRESS_FRAGMENT}
`;

export const UPDATE_REGION = gql`
  mutation AccountUpdateRegion($newRegion: CountryCode!) {
    accountUpdateRegion(newRegion: $newRegion) {
      errors {
        field
        message
      }
      user {
        id
        region {
          code
          country
        }
      }
    }
  }
`;
