//we are revisiting categories/collections later due to perms.
// eslint-disable-next-line no-unused-vars
const getCategoriesAndCollections = (item) => {
  let categoryAndCollection = {
    item_category: null,
    item_collection: null,
  };
  const categoryList = item?.variant?.product?.categories || [];
  const collectionList = item?.variant?.product?.collections || [];
  for (let [index, category] of categoryList.entries()) {
    if (index === 0) {
      categoryAndCollection.item_category = category?.name;
    } else {
      categoryAndCollection[`item_category${index}`] = category?.name;
    }
  }
  for (let [index, collection] of collectionList.entries()) {
    if (index === 0) {
      categoryAndCollection.item_collection = collection?.name;
    } else {
      categoryAndCollection[`item_collection${index}`] = collection?.name;
    }
  }
  return categoryAndCollection;
};

const mutateCheckoutLineItem = (item, checkout) => ({
  item_id: item?.id,
  item_name: item?.variant?.product?.name,
  currency: item?.variant?.pricing?.retailPrice?.currency,
  discount: item?.variant?.pricing?.discount,
  userDiscountedPrice: item?.variant?.pricing?.userDiscountedPrice,
  item_brand: checkout?.brand?.name,
  item_variant: item?.humanFriendlyVariantName,
  price: item?.totalPrice?.net?.amount,
  quantity: item?.quantity,
});

const getCheckoutItemsForAnalytics = (brandCheckouts) => {
  let items = [];
  for (let checkout of brandCheckouts) {
    for (let item of checkout.lines) {
      items.push(mutateCheckoutLineItem(item, checkout));
    }
  }
  return items;
};

export const getAnalyticsFromProduct = (product) => {
  return {
    currency: product?.pricing?.retailPrice?.currency,
    price: product?.pricing?.retailPrice?.net?.amount,
    items: [
      {
        item_id: product?.id,
        item_name: product?.name,
        currency: product?.pricing?.retailPrice?.currency,
        discount: product?.pricing?.discount,
        userDiscountedPrice: product?.pricing?.userDiscountedPrice,
        item_brand: product?.brand?.name,
        price: product?.pricing?.retailPrice?.net?.amount,
      },
    ],
  };
};

export const getAnalyticsFromCheckout = (checkout) => {
  const { brandCheckouts, pricing } = checkout;
  return {
    transaction_id: checkout?.id,
    value: pricing?.totalPrice?.gross?.amount,
    currency: pricing?.totalPrice?.currency,
    shipping: pricing?.shippingPrice?.net?.amount,
    tax: pricing?.totalPrice?.tax?.amount,
    totalMinusStoreCredit: pricing?.totalMinusStoreCredit?.gross?.amount,
    items: getCheckoutItemsForAnalytics(brandCheckouts),
  };
};

export const getAnalyticsFromCheckoutLine = (checkoutLinesAdd) => {
  const checkoutLine = checkoutLinesAdd?.lines[0];
  const totalLineValue = checkoutLinesAdd?.lines[0]?.totalPrice?.net?.amount;
  const totalQuantity = checkoutLinesAdd?.lines[0]?.quantity;
  const value =
    totalLineValue > 0 ? totalLineValue / totalQuantity : totalLineValue;
  return {
    currency: checkoutLinesAdd?.lines[0]?.totalPrice?.currency,
    value,
    items: [
      {
        item_id: checkoutLine?.id,
        item_name: checkoutLine?.variant?.product?.name,
        currency: checkoutLine?.variant?.pricing?.retailPrice?.currency,
        discount: checkoutLine?.variant?.pricing?.discount,
        userDiscountedPrice:
          checkoutLine?.variant?.pricing?.userDiscountedPrice,
        item_brand: checkoutLine?.variant?.product?.brand?.name,
        item_variant: checkoutLine?.humanFriendlyVariantName,
        price: checkoutLine?.totalPrice?.net?.amount,
        quantity: checkoutLine?.quantity,
      },
    ],
  };
};

export const getAnalyticsFromCheckoutLineUpdate = ({
  checkout,
  checkoutLinesUpdate,
}) => {
  const id = checkoutLinesUpdate?.lines[0]?.id;
  const totalLineValue = checkoutLinesUpdate?.lines[0]?.totalPrice?.net?.amount;
  const totalQuantity = checkoutLinesUpdate?.lines[0]?.quantity;
  const value =
    totalLineValue > 0 ? totalLineValue / totalQuantity : totalLineValue;

  for (let brandCheckout of checkout.brandCheckouts) {
    for (let item of brandCheckout.lines) {
      if (id === item.id) {
        return {
          value,
          currency: checkoutLinesUpdate?.lines[0]?.totalPrice?.currency,
          items: [
            {
              ...mutateCheckoutLineItem(item, brandCheckout),
              price: totalLineValue,
              quantity: totalQuantity,
            },
          ],
        };
      }
    }
  }
};
