import gql from 'graphql-tag';

export const GET_USER = gql`
  query GetUserDetails {
    me {
      id
      email
      isStaff
      dateJoined
      firstName
      lastName
      brandPermissions {
        brand {
          id
          name
        }
        permissionType
      }
    }
  }
`;
